.header {
    display: flex;
    justify-content: space-between;
}

.logo {
    width: 10rem;
    height: 3rem;
}

.header-menu {
    list-style: none;
    display: flex;
    gap: 2rem;
    align-items: center;
}

.header-menu>li {
    cursor: pointer;
}

.header-menu>li>a {
    text-decoration: none;
    color: #fff;
}

.header-menu>li>a:hover {
    color: var(--orange);
}

@media screen and (max-width: 768px) {
    .header .header-menu {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 99;
    }

    .header-menu {
        flex-direction: column;
        background-color: var(--appColor);
        padding: 2rem;
    }

    .header .mobile-menu {
        position: fixed;
        right: 1rem;
        top: 1rem;
        padding: 0.5rem;
        border-radius: 5px;
        background-color: var(--appColor);
        z-index: 999;
        cursor: pointer;
    }

    .mobile-menu-img {
        width: 1.5rem;
        height: 1.5rem;
    }

    .header .mobile-menu-overlay {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,0.5);
        z-index: 99;
    }
}